import $ from 'jquery';

$(document).ready(function() {

    $('.matchHeight').matchHeight();


    $(document).on('click','#sommaire-article a',function(){
        var h = $(this).attr('href');

        $('body,html').animate({
            scrollTop:$(h).offset().top -120
        }, 500);
        return false;
    });

    $('.slideDown').on('click',function(){
        $('.single_post__summary ul').slideToggle(300);
        $(this).toggleClass('open');
    });

    $('.share > i').on('click',function(){
        $('.share > ul').slideToggle(300);
        $(this).toggleClass('open');
    });

    /*
    $(window).on('load', function(){
        setTimeout(function(){
            $('.toggle-btn').parent().toggleClass('active').siblings().removeClass('active');
        }, 1000);
    });
     */

    $('.tooltip_js').on('click', function (){
        $('.tooltip_number').fadeToggle(300);
    });

    $('.mailjet-widget-form-group:nth-of-type(2) input').first().attr("placeholder", "Prénom");
    $('.mailjet-widget-form-group:nth-of-type(3) input').first().attr("placeholder", "Nom");
    $('.mailjet-widget-form-group:nth-of-type(4) input').first().attr("placeholder", "Profession");

    /*
    if(window.location.href === "https://audioinfos365.local/annonces/?_categories_annonces=emploi") {
        $('.cat_emploi').removeClass('d-none');
        $('.cat_emploi').addClass('d-block');

    } else if(window.location.href === "https://audioinfos365.local/annonces/") {
        $('.cat_emploi').removeClass('d-block');
        $('.cat_emploi').addClass('d-none');
    } else {

    }
    */

});