import $ from 'jquery';

$(document).ready(function() {
		
	$(window).scroll(function() {
		var sticky = $('header');
		var menu = $('.menu_fixed');
		var narvbar = $('.menu_fixed .navbar-nav');
		scroll = $(window).scrollTop();
	
		if (scroll >= 100) { 
			sticky.addClass('fixed');
			menu.addClass('menu_fixed_scroll d-flex justify-content-between align-items-center');
			narvbar.addClass('col-md-10');
			narvbar.removeClass('me-auto mr-auto');
		} else {
			sticky.removeClass('fixed');
			menu.removeClass('menu_fixed_scroll d-flex justify-content-between align-items-center');
			narvbar.removeClass('col-md-8');
			narvbar.addClass('me-auto mr-auto');

		}
	});

	$(window).resize(function() {
		var height = $('header').height();
		$('main > section').css({
			'margin-top': height
		});
	}).trigger('resize');

	//HAMBURGER
	
	$("#menu-toggle").click(function(e) {
	    e.preventDefault();
	    $("header").toggleClass("toggled");
	    $("#sidebar-wrapper").toggleClass("toggled");
	});
	
	$("#sidebar-wrapper .close").click(function(e) {
	    e.preventDefault();
	    $("header").removeClass("toggled");
		$("#sidebar-wrapper").toggleClass("toggled");
	});


	$('.navbar-nav .dropdown').hover(function() {
		$(this).find('.dropdown-menu').stop(true, true).delay(250).slideDown().css('display', 'flex');
	});

	$('.navbar-nav .dropdown > a').click(function(){
		location.href = this.href;
	});


	$('.dropdown-menu > li > .dropdown-menu').parent().addClass('dropdown-submenu').find(' > .dropdown-item').addClass('dropdown-toggle');
	$('.dropdown-submenu > a').on("click", function(e) {
		var dropdown = $(this).parent().find(' > .show');
		$('.dropdown-submenu .dropdown-menu').not(dropdown).removeClass('show');
	$(this).next('.dropdown-menu').toggleClass('show');
		e.stopPropagation();
	});
	$('.dropdown').on("hidden.bs.dropdown", function() {
		$('.dropdown-menu.show').removeClass('show');
	});



	/*SUBMENU NOT BOOTSTRAP*/

	/*
	let timeoutID = null;

	$('#menu-navigation-1 li.mega_menu > .nav-link').click((event) => {
		event.preventDefault();
		const navbar = $('.navbar');
		const menu = event.target.attributes.href.value.replace('#', '');

		if (timeoutID !== null)
			clearTimeout(timeoutID);

		let submenu = $('.submenu.submenu__' + menu).get(0);

		if (submenu && submenu.style && submenu.style.display === 'block') {
			navbar.removeClass('active');
			$(submenu).hide();
		} else {
			$('.submenu:not(.submenu__' + menu + ')').hide();
			navbar.addClass('active');
			$(submenu).show();
		}
	});

	 */

	/*
	$('#menu-navigation-1 li.mega_menu > .nav-link').blur((event) => {
		timeoutID = setTimeout(() => {
			$('.submenu').hide();
			$('.navbar').removeClass('active');
			timeoutID = null;
		}, 1000);
	});

	 */

	/*ADD CLASS ACTIVE PARENT INPUT*/
	/*
	var fields = [ ".searchbox input" ];
	$(fields).each(function() {
		$(this).on('focus', function() {
			$(this).parent().parent('.gfield').addClass('active');
		});

		$(this).on('blur', function() {
			if ($(this).val().length == 0) {
				$(this).parent().parent('.gfield').removeClass('active');
			}
		});
		if ($(this).val() != '') $(this).parent('.css').addClass('active');

	});
	*/

	/*OPEN A FIXED SEARCH BOX*/

	$(".topbar .search i").click(function(e) {
		e.preventDefault();
		$(".searchbox").fadeIn(300);
	});

	$(".topbar .search p").click(function(e) {
		e.preventDefault();
		$(".searchbox").fadeIn(300);
	});

	$(".search_mobile i").click(function(e) {
		e.preventDefault();
		$(".searchbox").fadeIn(300);
	});

	$(".search_mobile p").click(function(e) {
		e.preventDefault();
		$(".searchbox").fadeIn(300);
	});

	$(".search_mobile .close").click(function(e) {
		e.preventDefault();
		$(".searchbox").fadeOut(300);
	});

	$(".searchbox i").click(function(e) {
		e.preventDefault();
		$(".searchbox").fadeOut(300);
	});

	var habillage = document.getElementsByClassName('denta-habillage-background');
	if (habillage.length > 0) {
		document.body.classList.add('habillage');
	}

	var habillage = document.getElementsByClassName('prepr-habillage-background');
	if (habillage.length > 0) {
		document.body.classList.add('habillage');
	}

});